import React from "react";
import CookieConsent from "../components/cookieConsent";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ResultsContainer from "../components/resultsContainer";

export default function Wyniki() {
  return (
    <Layout>
      <CookieConsent/>
      <Seo/>
      <ResultsContainer />
    </Layout>
  );
}
