import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styles from "./resultsContainer.module.css";

export default function ResultsContainer() {
  const data = useStaticQuery(
    graphql`
      query {
        allPagesJson {
          edges {
            node {
              content
              parent {
                ... on File {
                  name
                }
              }
            }
          }
        }
      }
    `
  );

  const { content } = data.allPagesJson.edges.find((edge) => {
    return edge.node.parent.name === "results";
  }).node;

  return (
    <div className={styles.wrapper}>
      <main>
        <div className={styles.content} dangerouslySetInnerHTML={{__html: content}} />
      </main>
    </div>
  );
}
